@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);


$theme-colors: (
    "primary": #114B5F,
    "secondary": #1A936F,
    "third": #88D498,
    "forth": #C6DABF,
    "fifth": #F3E9D2,
    "cancel": rgb(97, 106, 114),
    "background": #f6f6f6,
);

$font-family-base: 'Noto Sans JP', sans-serif;
  

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";